import { Print } from '@mui/icons-material'
import { Button, FormControlLabel, Stack, Switch } from '@mui/material'

import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { generateUrl } from '#utils'

import { VisibilityRemark } from '../../apartments.page'

type Props = {
  fullAddress: string
  houseId: number
  changeVisibility: () => void
  visibilityRemark: VisibilityRemark
}

const ApartmentsTableHeader = ({ fullAddress, houseId, changeVisibility, visibilityRemark }: Props) => {
  const { isAdminRight, isSpecialistRight, isExpertRight, isDirectorRight } = useRightCurrentUser()
  const isRightGenerateReport = isAdminRight || isSpecialistRight || isDirectorRight

  const onPrintCard = () => {
    window.open(generateUrl(`pdf/house/info/${houseId}`), '_blank')
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      {fullAddress}

      {isExpertRight && (
        <FormControlLabel
          control={<Switch onChange={changeVisibility} checked={Boolean(Number(visibilityRemark))} />}
          label="Все замечания"
        />
      )}

      {isRightGenerateReport && (
        <Button onClick={onPrintCard} startIcon={<Print />} variant="contained">
          Сформировать отчет
        </Button>
      )}
    </Stack>
  )
}

export { ApartmentsTableHeader }
